$break-i5: 320px;
$break-i7: 375px;
$break-i7p: 425px;

$break-xs: 545px;
$break-sm: 769px;
$break-md: 993px;
$break-1024: 1025px;

@mixin max($break-point) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}

@mixin min($break-point) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}

@mixin screen($break-point-min, $break-point-max) {
  @media screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
    @content;
  }
}

@mixin ie11() {
  @media all and (-ms-high-contrast: none) {
    @content;
  }
}
