@import "media";

.wpgs-for .slick-arrow,
.wpgs-nav .slick-prev::before,
.wpgs-nav .slick-next::before {
  color: #a5a5a5 !important;
  transform: scale(0.65);
}

.woocommerce div.product .woocommerce-tabs ul.tabs {
  @include max($break-sm) {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }
}

.woocommerce div.product .woocommerce-tabs ul.tabs li {
  border-radius: 0 !important;
  @include max($break-sm) {
    width: 50% !important;
    padding: 0;
    text-align: center;
    margin: 0;
  }
}

.woocommerce div.product .woocommerce-tabs ul.tabs li a {
  @include max($break-sm) {
    font-size: 1rem !important;
  }
}

.woocommerce div.product .woocommerce-tabs ul.tabs li::before,
.woocommerce div.product .woocommerce-tabs ul.tabs li::after {
  @include max($break-sm) {
    display: none;
  }
}

.woocommerce-tabs {
  h2 {
    font-size: 1.8rem;
  }
  @include max($break-sm) {
    font-size: 1.6rem;
  }
}

.related.products,
.item-payment-tabs-list {
  h2,
  h3 {
    font-size: 1.8rem;
    @include max($break-sm) {
      font-size: 1.6rem;
    }
  }
}

.related.products {
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    width: 25% !important;
    text-align: center;
    float: none !important;
  }
}

.woocommerce div.product form.cart .variations label {
  color: black;
  font-size: 1.5rem;
  font-weight: normal;
  vertical-align: sub;
}

.woocommerce div.product form.cart .variations {
  width: auto !important;
}

.product_meta {
  .sku_wrapper {
    display: none !important;
  }
}

.product_title.entry-title {
  font-size: 1.8rem;
  @include max($break-sm) {
    font-size: 1.6rem;
  }
}

.woocommerce div.product form.cart .reset_variations {
  display: block;
}
.responsive-slide {
  table {
    font-size: 10px;
  }
  @include max($break-sm) {
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
  }
}

.content-area {
  width: 100% !important;
  float: none !important;
  margin-right: 0 !important;
}

.single-product div.product .images {
  margin-right: 0 !important;
}

.woocommerce-tabs ul.tabs {
  width: 20.411765% !important;
  margin-right: 0% !important;
  @include max($break-sm) {
    width: 100% !important;
  }
}

.woocommerce-tabs .panel {
  width: 75.705882% !important;
  @include max($break-sm) {
    width: 100% !important;
  }
}

.slick-slide img {
  margin: 0 auto;
}

.outboundlink {
  font-size: 1.5rem;
  color: white;
  background-color: black;
  display: inline-block;
  padding: 0.5em;
  margin-bottom: 1em;
  border: solid 1px black;
  &:hover {
    color: black;
    background-color: white;
  }
}

.woocommerce-products-header__title,
.cart_totals.calculated_shipping h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 10px;
}

.variations .label {
  text-align: left !important;
}

.single-product div.product table.variations select {
  max-width: 100% !important;
}

table.shop_table_responsive tbody tr td,
table.shop_table_responsive tbody tr th {
  width: 1em;
  @include max($break-sm) {
    width: 100%;
  }
}

.woocommerce-cart,
.woocommerce-checkout {
  .shareaholic-canvas {
    display: none;
  }
}

#ship-to-different-address {
  @include max($break-sm) {
    padding-left: 20px;
  }
}

input[type="text"],
select,
textarea {
  font-size: 16px;
  height: 2em;
}

h3 {
  font-size: 18px;
}

.taxonomy-list {
  dt {
    font-weight: normal;
    border-bottom: solid 1px gray;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
  }
  ul {
    list-style: none;
    margin-left: 0;
    @include max($break-sm) {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 10px;
    }
  }
  li {
    display: inline-block;
    margin-right: 2em;
    @include max($break-sm) {
      margin-right: 0;
    }
  }
}

.vbox-container img {
  margin: 0 auto;
}

ul.products {
  @include max($break-sm) {
    &:before,
    &:after {
      content: none !important;
    }
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    li {
      margin-bottom: 1em !important;
    }
  }
}
